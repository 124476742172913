.tag {
  text-align: center;
  color     : $color-primary;
  position  : relative;
  @extend .section;

  h1 {
    @extend .heading;
  }

  p {
    @extend .paragraph;
  }

  img {
    position  : absolute;
    top       : 50%;
    left      : 50%;
    transform : translate(-50%, -50%);
    width     : 12rem;
    height    : 12rem;
    object-fit: contain;
    opacity   : .6;

    @include respond-to('landscape') {
      width : 15rem;
      height: 15rem;
    }

    @include respond-to('desktop') {
      width     : 32rem;
      height    : 32rem;
      margin-top: -2rem;
    }
  }
}