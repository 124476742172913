.story {
    display: flex;
    @extend .section;
    &__desc {
        flex-basis: 70%;
        @include respond-to('landscape') {
            flex-basis: 60%;
        }
        h1 {
            @extend .heading;
          }
    }
    &__visual {
        flex-basis: 30%;
        position: relative;
        @include respond-to('landscape') {
            flex-basis: 40%;
        }
        img {
            width: 100%;
            position: absolute;
            right: 6.000rem;
            top: 7.000rem;
            transform: scale(2);
            z-index: -1;
            @include respond-to('landscape') {
                width: 60%;
                right: 11.000rem;
                top: 10.000rem;
            }
            @include respond-to('desktop') {
                width: 60%;
                right: 15.000rem;
                top: 6.000rem;
            }
            @include respond-to('huge') {
                width: 50%;
                right: 25.000rem;
                top: 10.000rem
            }
        }
    }
    &__scroll {
        height: 20.000rem;
        overflow-y: scroll;
        background-color: $color-primary;
        padding: 1.300em;
        @include rounded(5px);
        @include respond-to('landscape') {
            height: 30.000rem;
        }
        @include respond-to('desktop') {
            padding: 1.500rem 1.800rem;
        }
        @include respond-to('huge') {
            height: 40.000rem;
        }
        p {
            color: white;
            font-size: 1.200em;
            text-align: justify;
            text-indent: 1.500em;
            @include respond-to('desktop') {
                font-size: 1.400rem;
                line-height: 2.500rem;
            }
            @include respond-to('huge') {
                font-size: 1.500rem;
                line-height: 2.700rem;
            }
            &:not(:last-child) {
                margin-bottom: 1.000em;
            }
        }
    }
}