.navbar {
  padding        : 1rem 2rem;
  position       : fixed;
  z-index        : 100;
  display        : flex;
  align-items    : center;
  width          : 100%;
  top            : 0;
  justify-content: space-between;
  transition     : all 0.5s ease-out;

  @include respond-to('medium') {
    padding: 1.5rem 3rem;
  }

  @include respond-to('large') {
    padding: 1.5rem 4rem;
  }

  @include respond-to('desktop') {
    padding: 3rem 15rem;
  }

  &__logo {
    flex-basis: 22%;
    transition: all .5s ease-out;

    @include respond-to('landscape') {
      flex-basis: 12%;
    }

    @include respond-to('desktop') {
      flex-basis: 10%;
    }


    img {
      width: 100%;

    }
  }

  &__menu {
    background-color: rgba($color-primary, 0.96);
    position        : absolute;
    left            : 0;
    top             : 0;
    width           : 100%;
    height          : 100vh;
    display         : flex;
    align-items     : center;
    justify-content : center;
    flex-direction  : column;
    transition      : all 0.5s ease-out;

    @include respond-to('landscape') {
      background-color: transparent;
      position        : static;
      height          : auto;
      flex-direction  : row;
      width           : auto;
      flex-basis      : 85%;
      justify-content : flex-start;
    }

    @include respond-to('desktop') {
      flex-basis: 82%;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 2rem;

        @include respond-to('landscape') {
          margin-bottom: 0;
          margin-right : 2.5rem;
        }

        @include respond-to('large') {
          margin-right: 3.5rem;
        }
      }

      a {
        color         : $color-white;
        text-transform: uppercase;
        font-size     : 2.5rem;
        transition    : all .5s ease-out;

        @include respond-to('landscape') {
          font-size: 1.2rem;
        }

        @include respond-to('medium') {
          font-size: 1.4rem;
        }

        @include respond-to('large') {
          font-size: 1.9rem;
        }

        @include respond-to('desktop') {
          font-size: 1.8rem;
        }

        &.active,
        &:hover {
          padding         : 0.5rem 1rem;
          background-color: $color-white;
          @include rounded(3px);
          color: $color-primary;
        }
      }
    }

    &.show {
      transform: translateX(0);
      opacity  : 1;
    }

    &.hide {
      transform: translateX(-110%);
      opacity  : 0;

      @include respond-to('landscape') {
        transform: translateX(0);
        opacity  : 1;
      }
    }
  }

  &__icon {
    flex-basis: 78%;
    text-align: right;

    @include respond-to('landscape') {
      display: none;
    }

    i {
      font-size   : 2rem;
      margin-right: 1rem;
      color       : $color-white;
      transition  : all .3s ease-out;

      &:active {
        transform: scale(.6);
      }
    }
  }

  &.bg {
    &--transparent {
      background-color: transparent;
    }

    &--primary {
      background-color     : $color-primary;
      -webkit-border-radius: 0 0 10px 10px;
      -moz-border-radius   : 0 0 10px 10px;
      border-radius        : 0 0 10px 10px;
      position             : sticky;
      top                  : 0;

      &.desktop {
        @include respond-to('desktop') {
          padding: 1rem 15rem;

          .navbar__logo {
            flex-basis: 8%;
          }

          .navbar__menu {
            flex-basis: 88%;
          }
        }
      }
    }
  }
}