.footer {
  background-color: $color-secondary;
  color: white;
  display: flex;
  padding: 1.5rem 2rem;
  align-items: center;
  justify-content: center;

  @include respond-to("medium") {
    padding: 2rem 3rem;
  }
  @include respond-to("large") {
    padding: 3rem 4rem;
  }
  @include respond-to("desktop") {
    padding: 3rem 8rem;
  }
  @media screen and (min-width: 1200px) {
    padding: 3rem 12rem;
  }

  &__logo {
    flex-basis: 20%;

    img {
      width: 7rem;
      height: 3rem;
      object-fit: contain;

      @include respond-to("medium") {
        transform: scale(1.3);
      }
      @include respond-to("large") {
        transform: scale(1.5);
      }
      @include respond-to("desktop") {
        transform: scale(1.8);
      }
    }
  }

  &__tag {
    flex-basis: 60%;
    text-align: center;

    @include respond-to("medium") {
      font-size: 1.3rem;
    }
    @include respond-to("large") {
      font-size: 1.6rem;
      br {
        display: none;
      }
    }
    @include respond-to("desktop") {
      font-size: 1.4rem;
    }
  }

  &__icons {
    flex-basis: 20%;
    text-align: center;

    a {
      color: white;
      font-size: 1.7rem;
      transition: all 0.5s ease;
      @include respond-to("medium") {
        font-size: 2.4rem;
      }
      @include respond-to("large") {
        font-size: 2.8rem;
      }
      @include respond-to("desktop") {
        font-size: 2.5rem;
        &:hover {
          transform: scale(1.1);
          cursor: pointer;
        }
        &:active {
          transform: scale(0.9);
        }
      }

      &:not(:last-child) {
        margin-right: 1rem;

        @include respond-to("medium") {
          margin-right: 2rem;
        }
        @include respond-to("large") {
          margin-right: 3rem;
        }
      }
    }
  }
}
