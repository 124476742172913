@mixin rounded($pixel) {
    -webkit-border-radius: $pixel; 
    -moz-border-radius: $pixel; 
    border-radius: $pixel; 
}

$breakpoints: (
  "phone": (
    min-width: 20.062rem
  ),
  "landscape": (
    min-width: 30rem
  ),
  "medium": (
    min-width: 48rem
  ),
  "large": (
    min-width: 62rem
  ),
  "desktop": (
    min-width: 75rem
  ),
  "huge": (
    min-width: 90rem
  ),
  "ultra": (
    min-width: 120rem
  )
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == "string", unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}