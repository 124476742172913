.banner {
  background-color: $color-primary;
  padding         : 8em 2rem 5rem 2rem;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  position        : relative;
  overflow        : hidden;

  @include respond-to("phone") {
    padding: 10rem 2rem 5rem 2rem;
  }

  @include respond-to('medium') {
    padding: 10rem 3rem 5rem 3rem;
  }

  @include respond-to('large') {
    padding: 15rem 4rem 5rem 4rem;
  }

  @include respond-to('large') {
    padding: 15rem 10rem 5rem 10rem;
  }
  @media screen and (min-width: 1200px){
    padding: 20rem 8rem 10rem 8rem;
  }

  @media screen and (min-width: 1200px){
    padding: 20rem 12rem 10rem 12rem;
  }
  
  

  &__net {
    position : absolute;
    z-index  : 0;
    top      : 50%;
    left     : -3rem;
    transform: translateY(-50%);
    width    : 100%;
    @include respond-to('desktop') {
      width: 70%;
      left: 0;
      top: 55%;
    }
  }

  &__img {
    flex-basis: 45%;
    position  : relative;
    @include respond-to('desktop') {
      flex-basis: 40%;
    }

    img {
      width: 100%;
    }

    .glow {
      position : absolute;
      left     : -3rem;
      bottom   : 2rem;
      z-index  : 1;
      transform: scale(2);
      @include respond-to('desktop') {
       left: -10rem;
       animation: glow 4s ease-in-out infinite;
      }
    }

    .robo {
      position: relative;
      z-index : 2;
      @include respond-to('desktop') {
        animation: fly 4s ease-in-out infinite;
       }
    }
  }

  &__text {
    flex-basis: 50%;
    position  : relative;
    z-index   : 3;
    color     : $color-white;
    @include respond-to('desktop') {
      flex-basis: 55%;
    }
    h1 {
      font-size: 1.7rem;

      @include respond-to('landscape') {
        font-size: 2.5rem;
      }

      @include respond-to('medium') {
        font-size: 2.8rem;
      }

      @include respond-to('large') {
        font-size: 3.5rem;
      }
      @include respond-to('desktop') {
        font-size: 4rem;
        margin-bottom: 3rem;
      }
      
    }

    p {
      margin: 1.1rem 0 2rem 0;
      @extend .paragraph;
      br {
        display: none;
        @include respond-to('desktop') {
          display: inline;
        }
      }
    }

    button {
      padding         : 1.1rem 1.8rem;
      border          : none;
      background-color: $color-orange;
      text-transform  : uppercase;
      color           : $color-white;
      font-size       : 1rem;
      outline         : none;
      cursor          : pointer;
      transition      : all 0.5s ease;
      @include rounded(20px);
      @extend .semibold;

      @include respond-to('landscape') {
        font-size: 1.2rem;
      }

      @include respond-to('medium') {
        font-size: 1.5rem;
      }

      @include respond-to('large') {
        font-size: 1.8rem;
      }

      @include respond-to('desktop') {
        padding: 1.8rem 2.5rem;
      }

      &:hover {
        background-color: rgba($color-orange, 0.8);
      }

      &:active {
        transform: scale(0.85);
      }
    }
    
  }

}

@keyframes glow {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes fly {
  0% {
      transform: scale(1)
  }
  50% {
      transform: scale(1.05)
  }
  100% {
      transform: scale(1)
  }
}