@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap");

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.light {
  font-weight: 300;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
.heading {
  font-size: 1.6rem;
  color: $color-primary;
  margin-bottom: 1.4rem;
  @include respond-to('landscape') {
    font-size: 2.5rem;
    line-height: 2.7rem;
  }
  @include respond-to('medium') {
    font-size: 2.8rem;
  }
  @include respond-to('large') {
    font-size: 3.2rem;
    margin-bottom: 2.5rem;
  }
  @include respond-to('desktop') {
    font-size: 3.5rem;
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 1440px){
    font-size: 4rem;
  }
}
.paragraph {
  font-size: 1.3rem;
  line-height: 2rem;
  @extend .light;
  @include respond-to('landscape') {
    font-size: 1.5rem;
    line-height: 2.7rem;
  }
  @include respond-to('medium') {
    font-size: 1.7rem;
    line-height: 3rem;
  }
  @include respond-to('large') {
    font-size: 2rem;
    line-height: 3.5rem;
  }
  @include respond-to('desktop') {
    font-size: 2rem;
    line-height: 3.8rem;
  }
  @media screen and (min-width: 1440px){
    font-size: 2.2rem;
  }
}