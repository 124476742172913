.desc {
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-wrap      : wrap;
    position       : relative;
    overflow       : hidden;
    @extend .section;
    @include respond-to('desktop') {
        justify-content: space-between;
    }

    &__visual {
        flex-basis: 98%;

        @include respond-to('landscape') {
            margin-top: 3rem;
        }

        @include respond-to('large') {
            flex-basis: 90%;
        }
        @include respond-to('desktop') {
            flex-basis: 50%;
            order: 1;
        }
    }

    &__text {
        flex-basis: 100%;
        text-align: center;
        @include respond-to('desktop') {
            flex-basis: 47%;
            order: 2;
            text-align: left;
        }

        h1 {
            @extend .heading;
        }

        p {

            margin-bottom: 1.2rem;

            color: $color-primary;
            @extend .paragraph;
        }
    }

    &__card {
        background-color: #213367;
        padding         : 2rem 0 0 0;
        overflow        : visible;
        position        : relative;
        z-index         : 1;
        -webkit-box-shadow: 22px 23px 39px -8px rgba(0, 0, 0, .2);
      -moz-box-shadow   : 22px 23px 39px -8px rgba(0, 0, 0, .2);
      box-shadow        : 22px 23px 39px -8px rgba(0, 0, 0, .2);
        @include rounded(5px);
        @media screen and (min-width:1200px) {
            transform: scale(.85);
            padding: 3rem 0 0 0;
            margin-top: -3rem;
        }
        ul {
            li {
                display        : flex;
                align-items    : center;
                justify-content: space-between;
                padding        : 1rem;
                border-radius  : 3px;
               

                @include respond-to('landscape') {
                    padding: 1.5rem;
                }

                @include respond-to('large') {
                    padding: 2rem;
                }
                @include respond-to('desktop') {
                    padding: 3rem;
                }

                &:first-child,
                &:nth-child(4) {
                    background-color: #3B508A;
                    position        : relative;
                    -webkit-box-shadow: 22px 23px 39px -8px rgba(0, 0, 0, .3);
                    -moz-box-shadow   : 22px 23px 39px -8px rgba(0, 0, 0, .3);
                    box-shadow        : 22px 23px 39px -8px rgba(0, 0, 0, .3);
                }

                &:first-child {
                    right: -1.5rem;
                    @include respond-to('desktop') {
                        right: -3.5rem;
                    }
                }

                &:nth-child(4) {
                    left: -1.5rem;
                    @include respond-to('desktop') {
                        left: -3.5rem;
                    }
                }

                p {
                    color    : white;
                    font-size: 1.2rem;

                    @include respond-to('landscape') {
                        font-size: 1.6rem;
                    }

                    @include respond-to('large') {
                        font-size: 1.9rem;
                    }

                    &.red {
                        color: #ff7979;
                    }

                    &.green {
                        color: #6ab04c;
                    }

                    &.no {
                        // background-color: red;
                        flex-basis         : 3%;
                        text-align         : center;
                    }

                    &.name {
                        // background-color: green;
                        flex-basis         : 18%;
                    }

                    &.price {
                        flex-basis         : 17%;
                        // background-color: black;
                        text-align         : center;
                    }

                    &.up {
                        flex-basis         : 15%;
                        text-align         : center;
                        // background-color: blue;
                    }

                    &.amount {
                        flex-basis         : 34%;
                        // background-color: orange;
                        text-align         : center;
                    }
                }

                img {
                    flex-basis         : 8%;
                    height             : 2.5rem;
                    width              : 2.5rem;
                    object-fit         : contain;
                    // background-color: red;
                }
            }
        }
    }

    .grid {
        position  : absolute;
        right     : 1.5rem;
        bottom    : 2.8rem;
        width     : 15rem;
        height    : 5rem;
        object-fit: contain;
        z-index   : 0;

        @include respond-to('landscape') {
            transform: scale(1.3);
            right    : 2rem;
        }

        @include respond-to('large') {
            transform: scale(2);
            bottom   : 4rem;
        }
        @include respond-to('desktop') {
            left: 40%;
            bottom: 8rem;
        }
    }
}