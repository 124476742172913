@import './abstracts//mixins';
@import './abstracts/colors';

@import './base/base';
@import './base/typography';

@import './layouts/footer';
@import './layouts/navBar';

@import './pages/index/banner';
@import './pages/index/feature';
@import './pages/index/tag';
@import './pages/index/story';
@import './pages/index/desc';
@import './pages/status/error';

.section {
    padding: 5rem 2rem;
    @include respond-to('medium') {
        padding: 8rem 3rem;
    }
    @include respond-to('large') {
        padding: 8rem 4rem;
    }
  
    @media screen and (min-width: 1200px){
        padding: 8rem 8rem;
      }
      @media screen and (min-width: 1440px){
        padding: 8rem 12rem;
      }
}