.error {
    min-height: 92vh;
    background-color: $color-primary;
    position: relative;
    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        img {
            width: 40%;
        }
        h1 {
            font-size: 3rem;
            font-weight: 300;
            color: $color-white;
            span {
                font-weight: 500;
            }
            margin: 1rem 0;
        }
        p {
            color: $color-white;
            font-size: 1.8rem;
            line-height: 3rem;
        }
    }
}