.feature {
  display  : flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position : relative;
  overflow : hidden;
  @extend .section;

  &__text {
    flex-basis: 100%;
    text-align: center;
    color     : $color-primary;
    @include respond-to('desktop') {
      flex-basis: 48%;
      text-align: left;
    }
    h1 {
      @extend .heading;
    }

    p {
      @extend .paragraph;
      br {
        display: none;
        @include respond-to('desktop') {
          display: inline;
        }
      }
    }
  }

  &__visual {
    display        : flex;
    flex-basis     : 100%;
    justify-content: space-between;
    height         : 20rem;
    margin-top     : 2rem;
    position       : relative;
    @include respond-to('landscape') {
      height: 37rem;
      margin-top: 5rem;
    }
    @include respond-to('large') {
      height: 45rem;
      margin-top: 5rem;
    }
    @include respond-to('desktop') {
      flex-basis: 50%;
      flex-direction: column;
      height: auto;
    }
    .img {
      background-color  : $color-light;
      flex-basis        : 30%;
      overflow          : hidden;
      display           : flex;
      align-items       : center;
      justify-content   : center;
      flex-direction    : column;
      padding           : 2.5rem 1rem;
      -webkit-box-shadow: 22px 23px 39px -8px rgba(0, 0, 0, 0.07);
      -moz-box-shadow   : 22px 23px 39px -8px rgba(0, 0, 0, 0.07);
      box-shadow        : 22px 23px 39px -8px rgba(0, 0, 0, 0.07);
      @include rounded(5px);
      @include respond-to('desktop') {
        width: 45%;
        padding: 2rem .5rem;
      }

      &:nth-child(2) {
        align-self: flex-start;
        @include respond-to('desktop') {
          order: 3;
          align-self: flex-end;
          margin-top: -12rem;
        }
      }

      &:first-child,
      &:last-child {
        align-self: flex-end;
        
      }

      &:first-child {
        @include respond-to('desktop') {
          margin-bottom: -12rem;
          order: 1;
        }
      }
      &:last-child {
        @include respond-to('desktop') {
          // margin-top: -10rem;
          order: 2;
          align-self: flex-start;
        }
      }

      p {
        text-align : center;
        line-height: 1.3rem;
        margin-top : 0.8rem;
        color      : $color-primary;
        @extend .semibold;
        @include respond-to('landscape') {
          font-size: 1.3rem;
          margin-top: 2rem;
        }
        @include respond-to('medium') {
          font-size: 1.5rem;
        }
        @include respond-to('large') {
          font-size: 1.8rem;
          margin-top: 3rem;
        }
      }
      
      &:first-child {
        img {
          @include respond-to('landscape') {
            margin-right: -70%;
            width: 95%;
          }
          @include respond-to('medium') {
            width: 100%;
            transform: scale(1.4);
          }
          @include respond-to('large') {
            margin-right: -50%;
            width: 80%;
            transform: scale(1.4);
          }
          @include respond-to('desktop') {
            width: 80%;
            margin-right: -50%;
          }
        }
      }

      img {
        width: 100%;
        @include respond-to('large') {
          width: 80%;
        }
        
      }

      .financial {
        transform   : scale(1.6) translateY(-0.3rem);
        margin-right: -5rem;
      }
    }
  }

  .grid {
    position  : absolute;
    top       : -1rem;
    left      : 0;
    width     : 15rem;
    height    : 4rem;
    object-fit: contain;
    @include respond-to('landscape') {
      transform: scale(1.3);
    }
    @include respond-to('medium') {
      transform: scale(1.5);
      top: -.5rem;
      left: 1rem;
    }
    @include respond-to('large') {
      transform: scale(2);
    }
    @include respond-to('desktop') {
      top: 1rem;
      left: 5%;
    }
  }

  .box {
    position : absolute;
    bottom   : 0;
    width    : 15rem;
    height   : 4rem;
    left     : 40%;
    bottom   : 15%;
    transform: translateX(-50%);
    z-index: -1000;
    @include respond-to('landscape') {
      transform: scale(1.3);
      left: 30%;
    }
    @include respond-to('medium') {
      transform: scale(1.5);
    }
    @include respond-to('large') {
      transform: scale(2);
    }
    @include respond-to('desktop') {
      left: 45%;
      bottom: 28%;
    }
  }
  &__button {
    flex-basis: 100%;
    text-align: center;
    margin-top: 2rem;
    button {
      color: $color-white;
      background-color: #F8941B;
      border: none;
      outline: none;
      padding: .8rem 1.2rem;
      transition: all .5s ease;
      font-size: 1.3rem;
      cursor: pointer;
      @include  rounded(5px);
      @include respond-to('medium') {
        font-size: 1.7rem;
        padding: 1.2rem 1.8rem;
      }
      &:hover {
        @include respond-to("desktop") {
          transform: scale(1.08);
        }
      }
      &:active {
        transform: scale(.9);
      }
    }
  }
}